import React, { useState } from "react";
import ModalForm from "./modalForm";
import ContactUsSection from "./contact";
import FooterSection from "./footer";
import logo from "../../img/sdv.png";
import logo2 from "../../img/sdv2.png";
import corgi from "../../img/corgi.png";

function Referal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-[#ffc400]">
      <ModalForm toggleModal={toggleModal} isModalOpen={isModalOpen} />

      {/* Encabezado */}
      <div
        className="flex flex-col md:flex-row justify-between items-center p-4 bg-[#ffc400] text-white h-auto lg:h-[200px]"
        style={{
          borderBottomColor: "#000",
          borderBottomWidth: 3.5,
          // height: 200,
        }}
      >
        <div className="flex items-center space-x-4">
          <img src={logo} alt="samedayvet" className="w-10 md:w-12" />
          <h1 className="text-2xl md:text-4xl font-bold text-black text-center md:text-left">
            SameDayVet.com
          </h1>
        </div>
      </div>

      {/* Sección principal */}
      <section className="py-8 bg-cover bg-center text-white">
        <div className="flex justify-center font-bold mb-8 px-4">
          <p className="text-2xl md:text-6xl text-black text-center">
            Connecting You to Affordable Pet Care
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-10 mb-10">
          <p className="text-customGray text-lg md:text-2xl font-bold text-center">
            READY TO FIND YOUR VET?
          </p>
          <button
            onClick={toggleModal}
            className="text-customGray font-bold border-2 border-black w-4/5 md:w-96 py-4 transition-colors duration-300 hover:bg-black hover:text-white hover:border-white"
          >
            FIND MY AFFORDABLE VET CARE
          </button>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-9 mb-10">
          <p className="text-lg md:text-2xl font-bold text-customGray text-center">
            UNSURE WHERE TO START?
          </p>
          <button
            onClick={toggleModal}
            className="text-customGray font-bold border-2 border-black w-4/5 md:w-96 py-4 transition-colors duration-300 hover:bg-black hover:text-white hover:border-white"
          >
            USE OUR AI FREE PET ISSUE ANALYSYS
          </button>
        </div>

        <div className="flex justify-center px-4">
          <p className="text-black text-sm md:text-xl text-center">
            LET OUR AI ANALYZE YOUR PET ISSUE AND OFFER OPTIONS OR MORE INFO
          </p>
        </div>

        <div className="mt-8 px-4 md:px-32">
          <h2 className="text-black font-bold text-3xl md:text-6xl mb-4 text-center md:text-left">
            What We Do
          </h2>
          <p className="text-black text-sm md:text-xl text-justify">
            We help pet parents like you connect with affordable, reliable
            veterinary care in your area. Whether you're looking for community
            resources, government programs, or private veterinary clinics
            offering great deals, we’ll help you find the best options for your
            furry family members.
          </p>
        </div>

        <div className="mt-8 px-4 md:px-32">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center items-center">
              <img src={corgi} alt="samedayvet" className="w-3/4" />
            </div>
            <div>
              <p className="text-black text-sm md:text-xl mb-4">
                How It Works...
              </p>
              <p className="text-black text-sm md:text-xl">
                <span className="font-bold">
                  Community & Private Practice Offerings:
                </span>{" "}
                We help pet parents like you connect with affordable, reliable
                veterinary care in your area. Whether you're looking for
                community resources, government programs, or private veterinary
                clinics offering great deals, we’ll help you find the best
                options for your furry family members.
              </p>
              <button
                onClick={toggleModal}
                className="mt-4 mb-4 text-customGray font-bold border-2 border-black w-full md:w-3/4 py-4 transition-colors duration-300 hover:bg-black hover:text-white hover:border-white"
              >
                FIND MY AFFORDABLE VET CARE
              </button>
              <p className="text-black text-sm md:text-xl">
                <span className="font-bold">
                  AI-Powered Pet Issue Analysis:
                </span>{" "}
                Have a concern about your pet? Upload a photo or provide a
                description of the issue, and our advanced AI will analyze the
                problem to provide helpful insights and guidance.
              </p>
              <button
                onClick={toggleModal}
                className="mt-4 text-customGray font-bold border-2 border-black w-full md:w-3/4 py-4 transition-colors duration-300 hover:bg-black hover:text-white hover:border-white"
              >
                USE OUR AI FREE PET ISSUE ANALYSYS
              </button>
              <p className="text-black text-sm md:text-xl mt-6">
                <span className="font-bold">Important Note:</span> We do not
                offer veterinary services ourselves. Instead, we connect you
                with clinics and programs that can help.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contacto */}
      <ContactUsSection />
      {/* Pie de página */}
      <FooterSection logo2={logo2} />
    </div>
  );
}

export default Referal;
