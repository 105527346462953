import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
const FooterSection = ({ logo2 }) => {
  return (
    <>
      <footer className="bg-[#282a29] text-gray-200 py-16">
        <div className="max-w-6xl mx-auto px-4">
          {/* Logo y Título */}
          <div className="flex flex-col items-center mb-12">
            <div className="mb-4">
              <div className="">
                <img src={logo2} alt="samedayvet" className="w-24" />
              </div>
            </div>
            <h1 className="text-lg font-semibold tracking-wide">
              Phases & Spaces Inc.
            </h1>
          </div>

          {/* Contenido principal */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 border-t border-gray-700 pt-8">
            {/* Columna 1: Join our community */}
            <div className="flex flex-col border-white border-2 items-center py-2">
              <h2 className="text-lg font-semibold mb-4">Join our community</h2>
              <address className="not-italic text-sm leading-6">
                123 Anywhere St, Any City ST
                <br />
                12345
                <br />
                Tel: +123-456-7890
                <br />
                <a
                  href="mailto:hello@reallygreatsite.com"
                  className="underline hover:text-gray-300"
                >
                  info@azucarmultimedia.com
                </a>
              </address>
            </div>

            {/* Columna 2: Business Hours */}
            <div className="flex flex-col border-white border-2 items-center py-2">
              <h2 className="text-lg font-semibold mb-4">Business Hours</h2>
              <p className="text-sm leading-6">
                Monday - Friday: 9 AM — 6 PM
                <br />
                Saturday: 9 AM — 12 NN
              </p>
            </div>

            {/* Columna 3: Get social */}
            <div className="flex flex-col border-white border-2 items-center py-2">
              <h2 className="text-lg font-semibold mb-4">Get social</h2>
              <div className="flex space-x-4">
                {/* Aquí los íconos sociales */}
                {/* Ícono de Facebook */}
                <div className="text-4xl text-blue-600">
                  <FaFacebook />
                </div>
                {/* Ícono de Twitter */}
                <div className="text-4xl text-blue-400">
                  <FaTwitter />
                </div>
                {/* Ícono de Instagram */}
                <div className="text-4xl text-pink-600">
                  <FaInstagram />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-black">
        <p className="text-white text-center">
          &copy; {new Date().getFullYear()} SameDayVet. All rights reserved.
        </p>
      </div>
    </>
  );
};

export default FooterSection;
