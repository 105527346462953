import React, { useEffect, useState } from "react";
import globalVariables from "../../globalVariables";
import axios from "axios";
import check from "../../img/check_5610944.png";

const ContactUsSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [isModalSuccesOpen, setIsModalSuccessOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form data submitted:", formData);
    // Aquí puedes enviar los datos del formulario al servidor

    try {
      const response = await axios.post(`${globalVariables.domain}/contact`, {
        name: formData.name,
        company: formData.company,
        phone: formData.phone,
        email: formData.email,
        comment: formData.comment,
      });
      // console.log(response.data)
      if (response.data.success === true) {
        setIsModalSuccessOpen(true);
        setFormData({
          name: "",
          company: "",
          phone: "",
          email: "",
          comment: "",
        });

        // Esperar 0.5 segundos antes de cerrar el modal
        setTimeout(() => {
          setIsModalSuccessOpen(false);
        }, 1200); // 500 milisegundos
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="flex justify-center items-center min-h-screen px-10 bg-white">
      {isModalSuccesOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <img src={check} alt="samedayvet" className="w-1/5 animate-jump-in" />
        </div>
      )}
      <div className="max-w-4xl w-full">
        {/* Título */}
        <h1 className="text-4xl font-bold text-black mb-8">Contact Us</h1>

        {/* Formulario */}
        <form className="grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
          {/* Nombre */}
          <div className="flex flex-col">
            <label className="text-sm font-semibold text-black mb-2">
              NAME
            </label>
            <input
              type="text"
              name="name"
              className="border-2 border-black rounded-md p-2 focus:outline-none"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          {/* Empresa */}
          <div className="flex flex-col">
            <label className="text-sm font-semibold text-black mb-2">
              COMPANY
            </label>
            <input
              type="text"
              name="company"
              className="border-2 border-black rounded-md p-2 focus:outline-none"
              placeholder="Enter your company"
              value={formData.company}
              onChange={handleChange}
            />
          </div>

          {/* Teléfono */}
          <div className="flex flex-col">
            <label className="text-sm font-semibold text-black mb-2">
              PHONE
            </label>
            <input
              type="tel"
              name="phone"
              className="border-2 border-black rounded-md p-2 focus:outline-none"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          {/* Correo */}
          <div className="flex flex-col">
            <label className="text-sm font-semibold text-black mb-2">
              EMAIL
            </label>
            <input
              type="email"
              name="email"
              className="border-2 border-black rounded-md p-2 focus:outline-none"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          {/* Pregunta o comentario (toma ambas columnas) */}
          <div className="col-span-2 flex flex-col">
            <label className="text-sm font-semibold text-black mb-2">
              QUESTION OR COMMENT
            </label>
            <textarea
              name="comment"
              className="border-2 border-black rounded-md p-2 focus:outline-none resize-none"
              rows="4"
              placeholder="Type your message"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>
          </div>

          {/* Botón */}
          <div className="col-span-2 flex justify-end">
            <button
              type="submit"
              className="bg-blue-800 text-white py-2 px-6 rounded-md hover:bg-blue-900 transition-colors"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUsSection;
